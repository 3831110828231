import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Awards from './components/Awards';
import Menu from './components/Menu';
import Location from './components/Location';
import Reviews from './components/Reviews';
import InstagramFeed from './components/InstagramFeed';
import Footer from './components/Footer';
import SEO from './components/SEO';

function App() {
  return (
    <HelmetProvider>
      <div className="min-h-screen">
        <SEO />
        <Navbar />
        <Hero />
        <Awards />
        <Menu />
        <Location />
        <Reviews />
        <InstagramFeed />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;