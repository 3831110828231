import React, { useEffect, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

interface AutoCarouselProps {
  children: React.ReactNode[];
  className?: string;
  delayMs?: number;
  slideSize?: 'small' | 'medium' | 'large';
}

const AutoCarousel = ({ 
  children, 
  className = '', 
  delayMs = 5000,
  slideSize = 'medium'
}: AutoCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'start',
    slidesToScroll: 1,
    skipSnaps: false,
    breakpoints: {
      '(max-width: 640px)': { slidesToScroll: 1 },
      '(min-width: 641px)': { slidesToScroll: 1 }
    }
  });
  
  const autoplayRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!emblaApi) return;

    const autoplay = () => {
      if (autoplayRef.current) {
        clearTimeout(autoplayRef.current);
      }

      autoplayRef.current = setTimeout(() => {
        if (emblaApi.canScrollNext()) {
          emblaApi.scrollNext();
        } else {
          emblaApi.scrollTo(0);
        }
        autoplay();
      }, delayMs);
    };

    autoplay();

    return () => {
      if (autoplayRef.current) {
        clearTimeout(autoplayRef.current);
      }
    };
  }, [emblaApi, delayMs]);

  const getSlideSizeClass = () => {
    switch (slideSize) {
      case 'small':
        return 'flex-[0_0_100%] sm:flex-[0_0_50%] md:flex-[0_0_33.333%] lg:flex-[0_0_25%]';
      case 'large':
        return 'flex-[0_0_100%] md:flex-[0_0_50%]';
      default: // medium
        return 'flex-[0_0_100%] sm:flex-[0_0_50%] lg:flex-[0_0_33.333%]';
    }
  };

  return (
    <div className={`overflow-hidden ${className}`} ref={emblaRef}>
      <div className="flex">
        {children.map((child, index) => (
          <div
            key={index}
            className={`${getSlideSizeClass()} min-w-0 px-4`}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoCarousel;