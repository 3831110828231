import React from 'react';
import { Helmet } from 'react-helmet-async';
import { defaultSEOConfig } from '../utils/seo';

const SEO = () => {
  return (
    <Helmet>
      <title>{defaultSEOConfig.title}</title>
      <meta name="description" content={defaultSEOConfig.description} />
      <meta name="keywords" content={defaultSEOConfig.keywords} />
      <link rel="canonical" href={defaultSEOConfig.url} />
      
      {/* Open Graph / Social Media */}
      <meta property="og:type" content={defaultSEOConfig.openGraph.type} />
      <meta property="og:title" content={defaultSEOConfig.openGraph.title} />
      <meta property="og:description" content={defaultSEOConfig.openGraph.description} />
      <meta property="og:url" content={defaultSEOConfig.url} />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content={defaultSEOConfig.twitter.card} />
      <meta name="twitter:title" content={defaultSEOConfig.twitter.title} />
      <meta name="twitter:description" content={defaultSEOConfig.twitter.description} />
    </Helmet>
  );
};

export default SEO;