export const defaultSEOConfig = {
  title: "Ploughman's Deli - A Most Extraordinary Sandwich Quest | Houston, TX",
  description: "Experience unique sandwiches inspired by sci-fi and fantasy at Houston's most extraordinary deli. Order online or visit us in person.",
  keywords: "deli houston, themed restaurant, sci-fi restaurant, fantasy deli, unique sandwiches, houston food",
  url: "https://ploughmansdeli.com",
  openGraph: {
    type: "restaurant.restaurant",
    title: "Ploughman's Deli - Houston's Most Extraordinary Sandwich Quest",
    description: "Experience unique sandwiches inspired by sci-fi and fantasy at Houston's most extraordinary deli."
  },
  twitter: {
    card: "summary_large_image",
    title: "Ploughman's Deli - A Most Extraordinary Sandwich Quest",
    description: "Experience unique sandwiches inspired by sci-fi and fantasy at Houston's most extraordinary deli."
  }
};