import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, ExternalLink } from 'lucide-react';
import AutoCarousel from './AutoCarousel';
import { PiCarrot, PiShrimp } from "react-icons/pi";
import { GiCow, GiChicken } from "react-icons/gi";
import { LuStar, LuPiggyBank } from "react-icons/lu";

const Menu = () => {
  const specialties = [
    {
      name: "Roll the Hard Six",
      description: "Bold flavors, no shortcuts. Tender roast beef, honey mustard, and a crunch in every bite. Your go-to for greatness!",
      price: "$16",
      icon: <GiCow />
    },
    {
      name: "Italian Beef",
      description: "Hot Italian beef, jus, and melted provolone forge an unbeatable alliance. Ciabatta and pickled veg round it out!",
      price: "$14",
      icon: <GiCow />
    },
    {
      name: "Prawn Star",
      description: "Asian inspired pickled shrimp meets crispy bacon and sweet apple in a buttery lobster roll, with a balancing dab of mayo!",
      price: "$15",
      icon: <PiShrimp />
    },
    {
      name: "Chicken Salad Croissant",
      description: "A buttery croissant cradling a galaxy of organic chicken salad, grapes, almonds, and cosmic crunch.",
      price: "$15",
      icon: <GiChicken />
    },
    {
      name: "Meatball Sub",
      description: "Meatballs in spicy marinara, armored with provolone, parmesan, and pesto. A sub worthy of the multiverse.",
      price: "$17",
      icon: <LuPiggyBank />
    },
    {
      name: "Reuben",
      description: "Toasted rye and corned beef with beer-kissed kraut, russian dressing, and swiss. A gift from the flavor czar!",
      price: "$17",
      icon: <GiCow />
    },
    {
      name: "Margherita",
      description: "Fresh mozzarella, pesto, and balsamic on ciabatta—your taste buds’ ticket to an Italian star system.",
      price: "$13",
      icon: <PiCarrot />
    },
    {
      name: "Ham and Eggs",
      description: "A fusion of bone-in ham, egg salad, and mayo on ciabatta—classic comfort, warp speed flavor.",
      price: "$15",
      icon: <LuPiggyBank />
    },
    {
      name: "Build Your Own",
      description: "Make your own luck! Forge your ultimate sandwich with meats and cheeses of your choice. Choose wisely!",
      price: "$15",
      icon: <LuStar />
    }
  ];
  

  return (
    <section id="menu" className="py-14 bg-stone-100">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center mb-6">Our Menu</h2>
        
        <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-8 rounded-r-lg">
          <div className="flex items-center justify-center">
            <AlertCircle className="text-amber-500 mt-1 mr-3" />
            <div>
              <p className="text-amber-900 font-medium">Our menu changes as frequently as the Time Lord regenerates!</p>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <AutoCarousel delayMs={5000}>
            {specialties.map((item, idx) => (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: idx * 0.1 }}
                className="flex-[0_0_100%] min-w-0 sm:flex-[0_0_50%] md:flex-[0_0_25%] px-2"
              >
                <div className="bg-white p-4 rounded-lg shadow-lg h-full mb-4">
                  <div className="flex items-center mb-2">
                    <div className="text-amber-500 mr-2">
                      {React.cloneElement(item.icon, { size: 24 })}
                    </div>
                    <h3 className="text-xl font-semibold">{item.name}</h3>
                  </div>
                  <p className="text-gray-600 mb-2">{item.description}</p>
                  <p className="text-amber-500 font-semibold">{item.price}</p>
                </div>
              </motion.div>
            ))}
          </AutoCarousel>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-6">
            <iframe
              src="https://ploughmansdeli.getbento.com/online-ordering/ploughmans-deli/menu"
              className="w-full h-[800px] border-0"
              title="Online Menu"
              loading="lazy"
            ></iframe>
          </div>
          
          <div className="bg-gray-50 px-6 py-4 border-t border-gray-100">
            <a
              href="https://ploughmansdeli.getbento.com/online-ordering/ploughmans-deli/menu"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-amber-600 hover:text-amber-700"
            >
              <span>View Full Menu</span>
              <ExternalLink className="ml-2 h-4 w-4" />
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Menu;