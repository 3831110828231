import React from 'react';
import { motion } from 'framer-motion';
import { Award, Star, Shield, Trophy, Medal, Crown } from 'lucide-react';

const Awards = () => {
  const awards = [
    {
      title: "Best Houston Sandwiches",
      year: "2023",
      org: "Eater Houston",
      icon: Award,
      size: "large",
      url: "https://houston.eater.com/maps/houston-best-sandwiches-banh-mi-po-boys/ploughmans-deli-amp-cafe"
    },
    {
      title: "Reliably Delicious",
      year: "2023",
      org: "The Leader",
      icon: Award,
      size: "large",
      url: "https://www.theleadernews.com/food_drink/reviews/review-ploughman-s-a-reliably-delicious-deli/article_c79a115a-a326-5464-b9a4-919435bb7275.html"
    },
    {
      title: "Reader's Choice: Best Sandwich Shop",
      year: "2022",
      org: "The Leader",
      icon: Crown,
      size: "small",
      url: "https://www.theleadernews.com/business/readers-choice-2022-winners/article_b5d3db08-69ad-11ed-8b6b-d3ff55b4782c.html"
    },
    {
      title: "51 Best Delis in the Country",
      year: "2022",
      org: "Food Network",
      icon: Crown,
      size: "large",
      url: "https://www.foodnetwork.com/restaurants/photos/50-best-delis-by-state#:~:text=Texas%3A%20Ploughman%E2%80%99s%20Deli,the%20geeky%20ambiance."
    },
    {
      title: "Reader's Choice: Best Sandwich Shop",
      year: "2021",
      org: "The Leader",
      icon: Crown,
      size: "small",
      url: "https://www.theleadernews.com/business/leader-unveils-readers-choice-awards-winners-for-2021/article_fbc844bc-3904-11ec-85b9-63fe4bc3274a.html"
    },
    // {
    //   title: "Favorite Sandwich Spots",
    //   year: "2019",
    //   org: "Houston Press",
    //   icon: Crown,
    //   size: "small",
    //   url: "https://www.houstonpress.com/restaurants/houstons-best-5-sandwich-shops-of-2019-11411253"
    // },

    {
      title: "Houston’s 10 Best Sandwich Shops",
      year: "2019",
      org: "PaperCity Magazine",
      icon: Award,
      size: "small",
      url: "https://www.papercitymag.com/restaurants/houston-best-sandwich-shops-kenny-ziggys-mendocino-farms-rocky-subs/"
    },
    {
      title: "Houston's Best Sandwiches",
      year: "2018",
      org: "Houston Press",
      icon: Award,
      size: "small",
      url: "https://www.houstonpress.com/restaurants/the-ham-and-eggs-at-ploughmans-deli-and-cafe-is-a-sandwich-a-really-good-sandwhich-10798410"
    },
    // {
    //   title: "Six Best Sandwich Shops in Houston",
    //   year: "2019",
    //   org: "Houston Press",
    //   icon: Crown,
    //   size: "small",
    //   url: "https://www.houstonpress.com/restaurants/try-these-six-incredible-houston-sandwich-spots-11351384"
    // },
    // {
    //   title: "Best Sandwich in the Galaxy",
    //   year: "2023",
    //   org: "Houston Chronicle",
    //   icon: Crown,
    //   size: "medium",
    //   url: "https://www.houstonchronicle.com/food/article/best-sandwiches-houston"
    // },
    // {
    //   title: "One Ring to Feed Them All",
    //   year: "2023",
    //   org: "Texas Monthly",
    //   icon: Shield,
    //   size: "medium",
    //   url: "https://www.texasmonthly.com/food/best-restaurants-2023"
    // },
    // {
    //   title: "Best Food This Side of Caprica",
    //   year: "2022",
    //   org: "Space City Food Awards",
    //   icon: Trophy,
    //   size: "medium",
    //   url: "https://www.spacecityfoodawards.com/winners-2022"
    // },
    // {
    //   title: "Most Innovative Menu",
    //   year: "2023",
    //   org: "Houston Food Scene",
    //   icon: Star,
    //   size: "medium",
    //   url: "https://houstonfoodscene.com/awards-2023"
    // },
    // {
    //   title: "Excellence in Service",
    //   year: "2023",
    //   org: "Houston Restaurant Association",
    //   icon: Medal,
    //   size: "small",
    //   url: "https://www.houstonrestaurantawards.com/winners"
    // },
    // {
    //   title: "Best Theme Integration",
    //   year: "2022",
    //   org: "Culinary Innovation Awards",
    //   icon: Award,
    //   size: "medium",
    //   url: "https://www.culinaryinnovationawards.com/2022"
    // }
  ];

  const getSizeClasses = (size: string) => {
    switch (size) {
      case 'large':
        return 'md:col-span-2 md:row-span-2';
      case 'medium':
        return 'md:col-span-1 md:row-span-1';
      default:
        return 'md:col-span-1';
    }
  };

  return (
    <section id="awards" className="py-8 bg-stone-900 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center mb-8">Awards & Recognition</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 auto-rows-min">
          {awards.map((award, idx) => (
            <motion.a
              href={award.url}
              target="_blank"
              rel="noopener noreferrer"
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
              transition={{ duration: 0.5, delay: idx * 0.1 }}
              className={`${getSizeClasses(award.size)} bg-stone-800 p-5 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:bg-stone-700 transition-colors duration-200`}
            >
              <div className={`mb-4 ${award.size === 'large' ? 'scale-150' : award.size === 'medium' ? 'scale-125' : 'scale-100'}`}>
                <award.icon className="w-12 h-12 text-amber-400" />
              </div>
              <h3 className={`${award.size === 'large' ? 'text-2xl' : 'text-xl'} font-semibold mb-2 text-center`}>
                {award.title}
              </h3>
              <p className="text-amber-400">{award.year}</p>
              <p className="text-stone-400">{award.org}</p>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Awards;