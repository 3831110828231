import React from 'react';
import { Sword, Instagram, Facebook } from 'lucide-react';

const Footer = () => {
  const scrollToSection = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <footer className="bg-stone-900 text-white py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-12">
          <div>
            <div className="flex items-center mb-2">
              <Sword className="h-6 w-6 text-amber-400" />
              <span className="ml-2 text-lg font-semibold">Ploughman's Deli & Cafe</span>
            </div>
            <p className="text-stone-400">
              Serving Candlelight Oaks, Garden Oaks, Oak Forest, Shepherd Park Plaza, and other hungry galaxies far, far away..
            </p>
          </div>
  
          <div>
            <h3 className="text-lg font-semibold mb-2 ml-10">Follow Us</h3>
            <div className="flex space-x-4 ml-10">
              <a href="https://www.facebook.com/ploughmansdeli" target="_blank" rel="noopener noreferrer" className="text-stone-400 hover:text-amber-400">
                <Facebook />
              </a>
              <a href="https://www.instagram.com/ploughmansdeli" target="_blank" rel="noopener noreferrer" className="text-stone-400 hover:text-amber-400">
                <Instagram />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-2  ml-10">Hours</h3>
            <ul className="space-y-0 ml-10">
              <li className="text-stone-400">Mon-Fri: 11am - 3pm</li>
              <li className="text-stone-400">Sat: 11am - 4pm</li>
              <li className="text-stone-400">Sun: Closed</li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-2 ml-10">Contact</h3>
            <ul className="space-y-2 text-stone-400 ml-10">
              <li><a href="https://maps.app.goo.gl/VtSiDXPbXkEtDjRw5" target="_blank" rel="noopener noreferrer" className="text-stone-400 hover:text-amber-400">965 Pinemont Dr #100<br/>Houston, TX 77018</a></li>
              <li><a href="tel:8325813675" className="text-stone-400 hover:text-amber-400">(832) 581-3675</a></li>
              <li><a href="mailto:ploughmansdeli@gmail.com" className="text-stone-400 hover:text-amber-400">ploughmansdeli@gmail.com</a></li>
            </ul>
          </div>
        </div>
          

        
        <div className="mt-6 text-center text-stone-400">
          <p>&copy; {new Date().getFullYear()} Ploughman's Deli & Cafe. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;