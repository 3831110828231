import React, { useState, useEffect } from 'react';
import { Menu as MenuIcon, X, Sword } from 'lucide-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 80; // Height of the navbar
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setIsOpen(false);
    }
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-stone-900' : 'bg-stone-900/90 backdrop-blur-sm'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Sword className="h-8 w-8 text-amber-400" />
            <div className="ml-2">
              <div className="text-lg sm:text-xl font-semibold text-white">Ploughman's Deli</div>
              <div className="text-xs sm:text-sm text-amber-400">A Most Extraordinary Sandwich Quest</div>
            </div>
          </div>
          
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-6">
              <button 
                onClick={() => scrollToSection('menu')} 
                className="text-white hover:text-amber-400 px-3 py-2 text-sm font-medium transition-colors"
              >
                Menu
              </button>
              <button 
                onClick={() => scrollToSection('location')} 
                className="text-white hover:text-amber-400 px-3 py-2 text-sm font-medium transition-colors"
              >
                Location
              </button>
              <a 
                href="https://ploughmansdeli.getbento.com/online-ordering"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-amber-500 text-stone-900 px-4 py-2 rounded-md hover:bg-amber-400 transition-colors font-medium"
              >
                Order Now
              </a>
            </div>
          </div>
          
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-amber-400 focus:outline-none transition-colors"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-64' : 'max-h-0'} overflow-hidden bg-stone-900`}>
        <div className="px-4 pt-2 pb-3 space-y-2">
          <button 
            onClick={() => scrollToSection('menu')} 
            className="w-full text-left text-white hover:text-amber-400 block px-3 py-3 text-base font-medium border-b border-stone-800 transition-colors"
          >
            Menu
          </button>
          <button 
            onClick={() => scrollToSection('location')} 
            className="w-full text-left text-white hover:text-amber-400 block px-3 py-3 text-base font-medium border-b border-stone-800 transition-colors"
          >
            Location
          </button>
          <button 
            onClick={() => scrollToSection('gallery')} 
            className="w-full text-left text-white hover:text-amber-400 block px-3 py-3 text-base font-medium border-b border-stone-800 transition-colors"
          >
            Gallery
          </button>
          <div className="px-3 py-3">
            <a
              href="https://ploughmansdeli.getbento.com/online-ordering"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full block bg-amber-500 text-stone-900 px-4 py-3 rounded-md hover:bg-amber-400 transition-colors font-medium text-center"
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;