import React from 'react';
import { motion } from 'framer-motion';
import { Star, Quote } from 'lucide-react';
import AutoCarousel from './AutoCarousel';

// Import review profile images
import profile1 from '../assets/images/reviews/profile-1.jpg';
import profile2 from '../assets/images/reviews/profile-2.jpg';
import profile3 from '../assets/images/reviews/profile-3.jpg';
import profile4 from '../assets/images/reviews/profile-4.jpg';
import profile5 from '../assets/images/reviews/profile-5.jpg';
import profile6 from '../assets/images/reviews/profile-6.jpg';
import profile7 from '../assets/images/reviews/profile-7.jpg';

const Reviews = () => {
  const reviews = [
    {
      name: "Ahhhnold",
      rating: 5,
      text: "I need your clothes, your boots, and your sandwich. I'll be back!",
      image: profile1
    },
    {
      name: "Jean-Luc Picard",
      rating: 5,
      text: "I said 'Make it so!' And they did. Absolutely delightful.",
      image: profile2
    },
    {
      name: "Samwise Gamgee",
      rating: 5,
      text: "PO-TAY-TOE (salad)! Mashed, boiled, and sometimes in a stew. Precious sandwiches too!",
      image: profile3
    },
    {
      name: "Kyle Reese",
      rating: 5,
      text: "Come with me if you want to eat!",
      image: profile4
    },
    {
      name: "Admiral Adama",
      rating: 5,
      text: "So say we all! Best sandwiches in all twelve colonies. I will always roll the hard six!",
      image: profile5
    },
    {
      name: "Admiral Ackbar",
      rating: 5,
      text: "Take evasive maneuvers! It's a snack!!",
      image: profile6
    },
    {
      name: "Obi-Wan",
      rating: 5,
      text: "These ARE the sandwiches you're looking for!",
      image: profile7
    }
  ];

  return (
    <section id="reviews" className="py-10 bg-stone-900 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center mb-8">Tales from Across the Universe</h2>
        
        <AutoCarousel delayMs={5000} slideSize="small">
          {reviews.map((review, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: idx * 0.2 }}
              className="h-full"
            >
              <div className="bg-stone-800 p-6 rounded-lg relative h-full">
                <Quote className="absolute top-4 right-4 text-amber-400 opacity-20" size={40} />
                <div className="flex items-center mb-4">
                  <img
                    src={review.image}
                    alt={review.name}
                    className="w-12 h-12 rounded-full object-cover mr-4"
                  />
                  <div>
                    <h3 className="font-semibold">{review.name}</h3>
                    <div className="flex text-amber-400">
                      {[...Array(review.rating)].map((_, i) => (
                        <Star key={i} size={16} fill="currentColor" />
                      ))}
                    </div>
                  </div>
                </div>
                <p className="text-stone-300">{review.text}</p>
              </div>
            </motion.div>
          ))}
        </AutoCarousel>
      </div>
    </section>
  );
};

export default Reviews;