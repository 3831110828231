import React from 'react';
import { MapPin, Phone, Clock, Mail } from 'lucide-react';

const Location = () => {
  const hours = [
    { day: 'Monday', hours: '11 AM–9:30 PM' },
    { day: 'Tuesday', hours: '11 AM–9:30 PM' },
    { day: 'Wednesday', hours: '11 AM–9:30 PM' },
    { day: 'Thursday', hours: '11 AM–9:30 PM' },
    { day: 'Friday', hours: '11 AM–9:30 PM' },
    { day: 'Saturday', hours: '11 AM–3 PM' },
    { day: 'Sunday', hours: 'Closed' },
  ];

  return (
    <section id="location" className="py-8 bg-stone-900 text-white">
      <div className="max-w-7xl mx-auto px-4">
        {/* <h2 className="text-4xl font-semibold text-center mb-8">Find Us</h2> */}
        
        <div className="grid md:grid-cols-2 gap-12">
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <MapPin className="text-amber-400 mt-1" />
              <div>
                {/* <h3 className="font-semibold text-lg">Address</h3> */}
                <p className="text-stone-300">
                  965 Pinemont Dr #100<br />
                  Houston, TX 77018
                </p>
              </div>
            </div>
            
            <div className="flex items-start space-x-4">
              <Clock className="text-amber-400 mt-0" />
              <div>
                {/* <h3 className="font-semibold text-lg">Hours</h3> */}
                <div className="text-stone-300 space-y-0.5">
                  {hours.map((day) => (
                    <div key={day.day} className="grid grid-cols-2 gap-4">
                      <span>{day.day}</span>
                      <span>{day.hours}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="flex items-start space-x-4">
              <Phone className="text-amber-400 mt-1" />
              <div>
                {/* <h3 className="font-semibold text-lg">Phone</h3> */}
                <a href="tel:8325813675" className="text-stone-300">(832) 581-3675</a>
                {/* <p className="text-stone-300">(832) 581-3675</p> */}
              </div>
            </div>
            
            <div className="flex items-start space-x-4">
              <Mail className="text-amber-400 mt-1" />
              <div>
                {/* <h3 className="font-semibold text-lg">Email</h3> */}
                <a href="mailto:ploughmansdeli@gmail.com" className="text-stone-300">ploughmansdeli@gmail.com</a>
                {/* <p className="text-stone-300">ploughmansdeli@gmail.com</p> */}
              </div>
            </div>
          </div>
          
          <div className="h-[400px] rounded-lg overflow-hidden shadow-lg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.8881016241766!2d-95.4201304!3d29.8406761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c7c0b3ca577b%3A0xc35c5649e54df9a9!2sPloughman's%20Deli%20%26%20Cafe!5e0!3m2!1sen!2sus!4v1645556047693!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;