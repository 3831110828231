import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Utensils } from 'lucide-react';

// Import images
import heroImage01 from '../assets/images/hero/PD01.jpg';
import heroImage02 from '../assets/images/hero/PD02.jpg';
import heroImage03 from '../assets/images/hero/PD03.jpg';
import heroImage04 from '../assets/images/hero/PD04.jpg';
import heroImage05 from '../assets/images/hero/PD05.jpg';
import heroImage06 from '../assets/images/hero/PD06.jpg';
import heroImage07 from '../assets/images/hero/PD07.jpg';
import heroImage08 from '../assets/images/hero/PD08.jpg';
import heroImage09 from '../assets/images/hero/PD09.jpg';
import heroImage10 from '../assets/images/hero/PD10.jpg';

const heroImages = [
  { url: heroImage01, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage02, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage03, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage04, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage05, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage06, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage07, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage08, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage09, alt: "Ploughman's Deli & Cafe" },
  { url: heroImage10, alt: "Ploughman's Deli & Cafe" },
];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState(heroImages);

  useEffect(() => {
    const shuffledImages = [...heroImages].sort(() => Math.random() - 0.5);
    setImages(shuffledImages);
  }, []);

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
    }, 6000);

    return () => clearInterval(transitionInterval);
  }, [images.length]);

  const scrollToMenu = () => {
    document.getElementById('menu')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative h-[calc(100vh-4rem)] md:h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0">
        {images.map((image, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ 
              opacity: index === currentImageIndex ? 1 : 0,
              scale: index === currentImageIndex ? 1.05 : 1
            }}
            transition={{
              opacity: { duration: 1.5, ease: "easeInOut" },
              scale: { duration: 6, ease: "linear" }
            }}
            className="absolute inset-0"
          >
            <img
              src={image.url}
              alt={image.alt}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-stone-900/90 to-stone-800/70" />
          </motion.div>
        ))}
      </div>
      
      <div className="relative z-10 text-center text-white px-4 max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl sm:text-5xl md:text-7xl font-semibold mb-4 leading-tight">
            Ploughman's Deli & Cafe
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl mb-8 text-stone-200 leading-relaxed">
            Serving Candlelight Oaks, Garden Oaks, Oak Forest, and Shepherd Park Plaza!
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4 px-4">
            <a
              href="https://ploughmansdeli.getbento.com/online-ordering"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto bg-amber-500 text-stone-900 px-8 py-4 rounded-md hover:bg-amber-400 transition-colors flex items-center justify-center font-medium text-lg"
            >
              <Utensils className="mr-2" /> Order Now
            </a>
            <button
              onClick={scrollToMenu}
              className="w-full sm:w-auto border-2 border-amber-200 text-amber-200 px-8 py-4 rounded-md hover:bg-amber-200/10 transition-colors font-medium text-lg"
            >
              View Menu
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;